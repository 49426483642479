import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";

export type TimelineCard = {
	year: string;
	imageUrl: string;
	description: string;
};

export type TimelineGroup = {
	cards: TimelineCard[];
	title: string;
	yearRange: string;
};

export type TimelineProps = {
	content: TimelineGroup;
	type?: "primary" | "secondary" | "tertiary";
	lastGroup?: boolean;
	firstGroup?: boolean;
	startingAlignment?: "left" | "right";
};

const Padding = styled.div`
	display: block;
	width: 100%;
	height: 75px;
`;

const LgSpacer = styled.div`
	display: block;
	width: 50%;
	box-sizing: border-box;
	padding: 0;
	height: 75px;
	margin: 0;
	border-right: 1px solid rgba(0, 34, 85, 0.1);
`;

const SmSpacer = styled.div`
	display: block;
	width: 50%;
	box-sizing: border-box;
	padding: 0;
	height: 50px;
	margin: 0;
	border-right: 1px solid rgba(0, 34, 85, 0.1);
`;

const CardPiece = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
`;

const Left = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 50%;
	box-sizing: border-box;
	height: 285px;
	position: relative;
	border-right: 1px solid rgba(0, 34, 85, 0.1);
	img {
		max-height: 285px;
		width: auto;
		position: relative;
		z-index: 1;
		margin-bottom: 20px;
		display: inline-block;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.xl}) {
				height: 245px;
				img {
					height: 245px;
				}
			}
		`;
	}}
`;
const Right = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 50%;
	box-sizing: border-box;
	position: relative;
	text-align: right;
	height: 285px;
	img {
		max-height: 285px;
		width: auto;
		position: relative;
		margin-bottom: 20px;
		z-index: 1;
		display: inline-block;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.xl}) {
				height: 245px;
				img {
					height: 245px;
				}
			}
		`;
	}}
`;

const Date = styled.div`
	position: absolute;
	top: 50%;
	left: 100%;
	margin-left: -40px;
	z-index: 1;
	margin-top: -40px;
	text-align: center;
	width: 80px;
	border-radius: 80px;
	height: 80px;
	line-height: 85px;
	white-space: nowrap;
`;

const LineToImage = styled.div`
	position: absolute;
	top: 50%;
	margin-top: -0.5px;
	height: 1px;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(0, 34, 85, 0.1);
	width: 100%;
	z-index: 0;
	left: 0;
`;

const MobileCard = styled.div`
	width: 100%;
	max-width: 475px;
	margin: 32px auto 0px auto;
	img {
		margin-bottom: 15px;
		margin-top: 12px;
		width: 100%;
		height: auto;
		display: block;
	}
`;

const MobileSpacer = styled.div`
	width: 100%;
	height: 42px;
	display: block;
`;

const Timeline: React.FC<TimelineProps> = ({
	content,
	type = "primary",
	lastGroup = false,
	firstGroup = false,
	startingAlignment = "left",
}) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="0" type={type}>
					{firstGroup === true ? <Padding /> : <LgSpacer />}
					<Typography
						type={type}
						variant="h2"
						css={css`
							letter-spacing: -2px;
							margin: 10px 0px 10px 0px;
							text-align: center;
						`}
					>
						{content.title}{" "}
						<span
							css={css`
								color: ${theme.colors.primary.gray} !important;
							`}
						>
							({content.yearRange})
						</span>
					</Typography>
					<LgSpacer />
					{content.cards.map((card: TimelineCard, index: number) => {
						return (
							<CardPiece key={index}>
								<Left theme={theme}>
									<Date
										css={css`
											background-color: ${theme.colors.primary.header};
										`}
									>
										<Typography
											color={theme.colors.primary.white}
											variant="h4"
											css={css`
												margin: 0;
												display: inline-block;
												width: auto;
												height: auto;
											`}
										>
											{card.year}
										</Typography>
									</Date>
									{index % 2 === 0 ? (
										startingAlignment === "left" ? (
											<>
												<img src={card.imageUrl} />
												<LineToImage />
												<Typography
													css={css`
														padding-right: calc(100% - 435px);
														display: block;
														width: 100%;
														@media (max-width: ${theme.breakpoints
																.xl}) {
															padding-right: calc(100% - 370px);
														}
													`}
													type={type}
													size="md"
												>
													{card.description}
												</Typography>
											</>
										) : (
											<></>
										)
									) : startingAlignment === "right" ? (
										<>
											<img src={card.imageUrl} />
											<LineToImage />
											<Typography
												css={css`
													padding-right: calc(100% - 435px);
													display: block;
													width: 100%;
													@media (max-width: ${theme.breakpoints.xl}) {
														padding-right: calc(100% - 370px);
													}
												`}
												type={type}
												size="md"
											>
												{card.description}
											</Typography>
										</>
									) : (
										<></>
									)}
								</Left>
								<Right theme={theme}>
									{index % 2 === 0 ? (
										startingAlignment === "right" ? (
											<>
												<img src={card.imageUrl} />
												<LineToImage />
												<Typography
													css={css`
														padding-left: calc(100% - 435px);
														display: block;
														text-align: left;
														width: 100%;
														@media (max-width: ${theme.breakpoints
																.xl}) {
															padding-left: calc(100% - 370px);
														}
													`}
													type={type}
													size="md"
												>
													{card.description}
												</Typography>
											</>
										) : (
											<></>
										)
									) : startingAlignment === "left" ? (
										<>
											<img src={card.imageUrl} />
											<LineToImage />
											<Typography
												css={css`
													padding-left: calc(100% - 435px);
													display: block;
													text-align: left;
													width: 100%;
													@media (max-width: ${theme.breakpoints.xl}) {
														padding-left: calc(100% - 370px);
													}
												`}
												type={type}
												size="md"
											>
												{card.description}
											</Typography>
										</>
									) : (
										<></>
									)}
								</Right>
							</CardPiece>
						);
					})}
					<LgSpacer />
					<SmSpacer />
					<SmSpacer />
					{lastGroup === true ? <Padding /> : <></>}
				</ContentSection>
			</Media>
			<Media
				lessThan="lg"
				css={css`
					height: auto;
				`}
			>
				{content.cards.map((card: TimelineCard, index: number) => {
					return (
						<MobileCard key={index}>
							<Typography
								variant="h3"
								css={css`
									margin: 0px;
								`}
								type={type}
							>
								{card.year}
							</Typography>
							<img src={card.imageUrl} />
							<Typography type={type} size="md">
								{card.description}
							</Typography>
						</MobileCard>
					);
				})}
				<MobileSpacer />
			</Media>
		</>
	);
};

export default Timeline;
